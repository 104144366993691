<template>
  <WorkSetFilters
    :filters="this.filters"
    :onChange="this.onFiltersChange"
    :is-fetching="this.isFetching"
    :get-data="this.getData"
  />
  <CustomTable
    title="Конструктивы"
    :rowsData="this.rowsData"
    :columns="this.getHandbookWorksetsColumns(this.filters.grouping_by, this.isContractorSelected)"
    :loading="isFetching"
    :custom-body="true"
    :small="true"
    :add-btn="!!isContractorSelected"
    add-btn-title="Привязать конструктив подрядчику"
    :onAddClick="() => this.handleContractorWorksetChange()"
    :show-search="true"
    :rowsDataGroupMode="true"
    :groupProps="{groupNameProp: 'group_name', groupChildrenProp: 'work_sets', childrenKeys: [
      'contractor_work_set_id',
      'service_call_work_set_group_name',
      'speciality_name',
      'work_set_check_group_name',
      'work_set_name',
      'work_set_price',
    ]}"
    :striped="false"
    :sticky-header="true"
  >
    <template #customBody="props">
      <q-tr class="groupWrapper">
        <q-td colspan="100%" class="group">
          {{ props.row.group_name }}
        </q-td>
      </q-tr>
      <q-tr v-for="item in props.row.work_sets">
        <custom-table-cell align="center" verticalAlign="center">
          {{item.work_set_id}}
        </custom-table-cell>
        <custom-table-cell align="left" verticalAlign="center">
          <div class="workSetName">
            <div>
              {{item.work_set_name}}
              <span
                v-if="!!item.service_call_work_set_group_name"
                class="workSetDepend"
                >({{ item.service_call_work_set_group_name }})
                <q-tooltip
                  >Зависимая группа работ. Эта услуга вызовется автоматически
                  после завешения этой группы работ. Работает только для тех
                  конструктивов, где стоит Вызов мастера. Например, вызов
                  мастера на душ кабины после окончания работы Выравнивание
                  стен.</q-tooltip
                >
              </span>
            </div>
            <CustomPopup v-if="isContractorSelected">
              <template #head>
                <q-chip
                  class="remontCounts"
                  color="primary"
                  size="sm"
                  text-color="white"
                  >{{ item.remont_count }}
                  <q-tooltip
                    >Кол-во ремонтов, в которых используется этот
                    конструктив</q-tooltip
                  >
                </q-chip>
              </template>
              <template #default>
                ID ремонтов:
                <br />
                {{ item.remont_ids }}
              </template>
            </CustomPopup>
          </div>
        </custom-table-cell>
        <custom-table-cell align="left" verticalAlign="center">
          {{this.filters.grouping_by === 'WORK_SET_CHECK_GROUP' ? item.speciality_name : item.work_set_check_group_name}}
        </custom-table-cell>
        <custom-table-cell align="center" verticalAlign="center">
          <div class="workSetEssential">
            <span>Вызов мастера</span
            ><CheckOrClose small :value="item.is_call" />
            <q-tooltip
              >По этой работе обязательно надо вызвать мастера</q-tooltip
            >
          </div>
          <div class="workSetEssential">
            <span>Поставщик SR</span
            ><CheckOrClose small :value="item.is_service" />
            <q-tooltip>Это услуга или работа мастера</q-tooltip>
          </div>
          <div class="workSetEssential">
            <span>Оплата онлайн</span
            ><CheckOrClose small :value="item.is_pay_online" />
            <q-tooltip
              >Работа, по которой платим ли мы мастеру или нет</q-tooltip
            >
          </div>
        </custom-table-cell>
        <custom-table-cell
          align="center"
          verticalAlign="center"
          v-if="this.isContractorSelected"
        >
          <CustomPopup width="400" :noPadding="true">
            <template #head>
              <div class="hiddenWrapper">
                <CustomIcon
                  icon="fa-solid fa-circle-down"
                  green
                  title="Открыть список вложенных конструктивов"
                />
                <span
                  >{{ item.nested_work_sets?.length || 0 }}
                  <q-tooltip>Кол-во вложенных конструктивов</q-tooltip>
                </span>
              </div>
            </template>
            <template #default>
              <WorkSetsHidden
                :contractor_work_set_id="item.contractor_work_set_id"
                :filters="this.filters"
                :rows-data="item.nested_work_sets || []"
                :onDataChange="this.onDataChange"
              />
            </template>
          </CustomPopup>
        </custom-table-cell>
        <custom-table-cell
          align="right"
          verticalAlign="center"
          v-if="this.isContractorSelected"
        >
          <div v-if="item.without_pay === false">
            <Amount :value="item.work_set_price" />
            <div
              class="worksetPriceFooter"
              v-if="!!item.contractor_work_set_id"
            >
              <CustomIcon
                @click="() => this.handleAddPrice(item.contractor_work_set_id)"
                icon="add"
                title="Добавить цену"
                green
              />
              <CustomPopup width="350" :noPadding="true">
                <template #head>
                  <CustomIcon icon="history" green title="История цен" />
                </template>
                <template #default>
                  <WorkSetPriceHistory
                    :contractor_work_set_id="item.contractor_work_set_id"
                    :filters="this.filters"
                    :onDataChange="this.onDataChange"
                  />
                </template>
              </CustomPopup>
            </div>
          </div>
          <div v-else-if="item.without_pay === true">
            <CustomIcon icon="block" green title="Работы без оплаты" />
          </div>
          <div v-else="item.without_pay === null">Нет цены</div>
        </custom-table-cell>
        <custom-table-cell align="center" vertical-align="center">
          <div class="actions">
            <CustomIcon
              @click="() => isContractorSelected ? this.handleContractorWorksetChange(item) : this.handleWorksetChange(item)"
              icon="edit"
            />
            <CustomIcon
              v-if="isContractorSelected"
              icon="trash"
              title="Отвязать конструктив от подрядчика"
              approve-mode
              :approve="() => onRemoveContractorWorkSet(item.contractor_work_set_id)"
            />
          </div>
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import CheckOrClose from "@/components/common/CheckOrClose";
import CustomIcon from "@/components/common/CustomIcon";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import { MODAL_NAMES } from "@/constants";
import { appActions } from "@/services/store/modules/app_module/app_actions";
import { addContractorWorkSet, addWorkSetPrice, getHandbookWorksets, removeContractorWorkSet, updateContractorWorkSet, updateHandbookWorkset } from "@/views/handbook/WorkSets/services";
import WorkSetFilters from "./filters.vue";
import { getHandbookWorksetsColumns } from './services';
import CustomPopup from "@/components/common/CustomPopup";
import Amount from "@/components/common/Amount";
import WorkSetPriceHistory from "./WorkSetPriceHistory.vue";
import WorkSetsHidden from "./WorkSetHidden.vue";

export default {
  name: "HandbookWorkSets",
  components: {CustomIcon, CheckOrClose, CustomTableCell, CustomTable,CustomPopup,Amount, WorkSetFilters, WorkSetPriceHistory, WorkSetsHidden},
  data() {
    return {
      rowsData: [],
      isFetching: false,
      filters: {
        company_id: 1,
        contractor_main_id: '',
        remont_id: '',
        nested_work_set_id: '',
        work_set_type: 'ALL',
        remont_status: 'ALL',
        grouping_by: 'WORK_SET_CHECK_GROUP',
      },
      savedFilters: {
        company_id: 1,
        contractor_main_id: '',
        remont_id: '',
        work_set_type: 'ALL',
        remont_status: 'ALL',
        grouping_by: 'WORK_SET_CHECK_GROUP',
      }
    }
  },
  computed: {
    isContractorSelected() {
      return !!this.savedFilters.contractor_main_id
    }
  },
  methods: {
    getHandbookWorksetsColumns,
    async getData(filters = {}) {
      this.isFetching = true
      const res = await getHandbookWorksets(filters)
      this.savedFilters = {...filters}
      this.isFetching = false
      this.rowsData = res || []
    },
    onDataChange(data) {
      this.rowsData = data
    },
    onFiltersChange(key, value) {
      this.filters[key] = value;
    },
    handleWorksetChange(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.EDIT_HANDBOOK_WORKSET, {
        body: row,
        title: 'Изменение конструктива',
        onSubmit: body => this.handleChangeWorkset(row?.work_set_id, body)
      }));
    },
    async handleChangeWorkset(work_set_id, body) {
      const res = await updateHandbookWorkset(work_set_id, body, this.filters)
      if(!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    handleContractorWorksetChange(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.ADD_HANDBOOK_CONTRACTOR_WORKSET, {
        body: row,
        title: row ? 'Изменение конструктива' : 'Добавление конструктива',
        onSubmit: body => this.onChangeContractorWorkset(row?.contractor_work_set_id, body)
      }));
    },
    async onChangeContractorWorkset(contractor_work_set_id, body) {
      const res = contractor_work_set_id ? await updateContractorWorkSet(contractor_work_set_id, body, this.filters)
      : await addContractorWorkSet(body, this.filters)
      if(!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onRemoveContractorWorkSet(contractor_work_set_id) {
      const res = await removeContractorWorkSet(contractor_work_set_id, this.filters)
      if(!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    handleAddPrice(contractor_work_set_id) {
      if(!contractor_work_set_id) return
      this.$store.commit(appActions.showModal(MODAL_NAMES.ADD_WORKSET_PRICE, {
        title: 'Добавить цену конструктива',
        onSubmit: body => this.onSubmitAddPrice({...body, contractor_work_set_id})
      }));
    },
    async onSubmitAddPrice(body) {
      const res = await addWorkSetPrice(body, this.filters)
      if(!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.groupWrapper {
    position: sticky;
    top: 48px;
    z-index: 49;
}
.group {
    padding: 10px 15px !important;
    background-color: #dde9ff !important;
    font-weight: bold;
    font-size: 14px;
    color: #0a1b3a;
}
.workSetName {
  display: flex;
  align-items: center;
  gap: 5px;
}
.workSetDepend {
  font-size: 12px;
  color: #1952d9;
}
.workSetEssential {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  height: 24px;
  width: 113px;

  & > span {
    line-height: 14px;
    font-size: 12px;
    white-space: nowrap;
  }
  &:last-child{
    border: none
  }
}
.remontCounts {
  cursor: pointer;
}
.worksetPriceFooter {
  padding-top: 8px;
  margin-top: 8px;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  gap: 4px;
}
.hiddenWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 600;
  color: green;
}
.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
</style>
