<template>
  <q-linear-progress indeterminate v-if="this.isFetching" />
  <NotFoundText v-else-if="this.measure && !this.data?.ds_id" />
  <CreateDSTemplate
    v-else
    cancelBtnText="Закрыть"
    :submitBtnText="this.data?.ds_id ? 'Сохранить' : 'Создать'"
    :disabled="this.isSubmitDisabled"
    :cancelApproveMode="this.cancelApproveMode"
    :on-submit="this.data?.ds_id ? this.updateDS : this.createDS"
    :on-cancel="this.onCancel"
    :loading="this.loading"
    :hideBack="!!this.measure"
    :label="this.dsData?.ds_type_name"
  >
    <div class="form">
      <div v-if="!this.measure" class="form__header">
        <div class="form__header_inputs">
          <div class="form__header_item">
            <CustomDate
              :full-width="true"
              :disable-past="true"
              placeholder="Дата"
              :value="this.formData.ds_date || ''"
              @change_date="(value) => onChange('ds_date', value)"
            />
          </div>
        </div>
        <div class="form__header_info" v-if="this.data">
          <p>Разница для ДС на изменение состава комнат и площадей:</p>
          <div class="form__header_infoFlex">
            <div>
              По материалам
              <br />
              <span>{{ this.numberWithCommas(this.data.material_diff) }}</span>
            </div>
            <div>
              В работах
              <br />
              <span>{{ this.numberWithCommas(this.data.work_diff) }}</span>
            </div>
            <div>
              В услугах
              <br />
              <span>{{ this.numberWithCommas(this.data.service_diff) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form__content" v-if="this.rooms">
        <div class="form__content_item">
          <CustomInput
            :integer="true"
            label="Сумма ДС"
            type="number"
            :disable="true"
            :value="this.data.ds_sum"
            @change_input="(value) => onChange('ds_sum', value)"
          />
        </div>
        <div class="form__content_item">
          <div>Высота потолка</div>
          <CustomInput
            label=""
            type="number"
            :show-disable="true"
            :value="this.formData.wall_height"
            @change_input="(value) => onChange('wall_height', value)"
          />
        </div>
        <div class="form__content_rooms">
          <div class="form__content_item title">
            <div>Комнаты</div>
            <div>Площадь</div>
            <q-btn
              size="sm"
              title="Добавить"
              color="deep-purple-13"
              round
              dense
              @click="() => onAddClick && onAddClick()"
              icon="add"
            />
          </div>
          <div v-for="item in this.editedRooms" class="form__content_item">
            <div>
              {{ item.room_name }}
              <span
                >{{ item.prev_room_area ? `(${item.prev_room_area})` : '' }}
                <q-tooltip>Изначальная площадь</q-tooltip>
              </span>
              <span
                v-if="item.action_code === this.dsRoomActionCodes.created || item.initial_action_code === this.dsRoomActionCodes.created"
                class="room__new"
                >new</span
              >
              <span
                v-if="item.action_code === this.dsRoomActionCodes.deleted"
                class="room__deleted"
              >
                <q-icon name="delete_forever" />
              </span>
            </div>
            <CustomInput
              label=""
              type="number"
              :disable="item.action_code === this.dsRoomActionCodes.deleted"
              :value="item.room_area"
              :bold="true"
              :autoMinWidth="true"
              @change_input="(value) => onRoomChange('room_area', value, item.room_id)"
            />
            <div class="flex justify-center">
              <CustomIcon
                :title="item.action_code === this.dsRoomActionCodes.deleted ? 'Восстановить' : 'Удалить'"
                :green="item.action_code === this.dsRoomActionCodes.deleted"
                :icon="item.action_code === this.dsRoomActionCodes.deleted ? 'trash_restore' : 'trash'"
                approve-mode
                :approve="() => this.actionWithRoom(item.room_id, item.action_code, item)"
                :loading="this.deleteLoading[item.room_id]"
                :approve-title="item.action_code === this.dsRoomActionCodes.deleted ? 'Восстановить комнату?' : 'Удалить комнату?'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </CreateDSTemplate>
</template>

<script>
import CreateDSTemplate from "@/views/ClientRequests/remontDS/dsCreationTemplates/template";
import {createRoomChangeDS, dsRoomActionCodes, getRoomChangeDSData, updateRoomChangeDS} from "@/views/ClientRequests/remontDS/services";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import CustomInputNumber from "@/components/form/CustomInputNumber";
import CustomDate from "@/components/form/CustomDate";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";
import CustomIcon from "@/components/common/CustomIcon";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {numberWithCommas} from "@/services";
import NotFoundText from "@/components/common/NotFoundText";

export default {
  name: "RoomChange",
  components: {NotFoundText, CustomIcon, CustomInput, CustomCheckbox, CustomDate, CustomInputNumber, CreateDSTemplate},
  props: ['onCancel', 'updateData', 'client_request_id', 'dsData', 'measure'],
  data() {
    return {
      dsRoomActionCodes,
      loading: false,
      isFetching: false,
      deleteLoading: {},
      savedData: null,
      isFormChanged: !!this.dsData?.ds_id,
      data: null,
      rooms: null,
      formData: {
        ds_type_id: this.dsData?.ds_type_id,
        ds_date: new Date().toLocaleDateString('ru'),
      }
    }
  },
  mounted() {
    if (this.dsData?.ds_id)
      this.getDS()
  },
  computed: {
    isSubmitDisabled() {
      if (!this.savedData) return false
      let isChanged = false
      const roomChangeableFields = ['room_area']
      const {rooms, formData: formDataSaved} = this.savedData
      Object.keys(this.formData).forEach(key => {
        if (this.formData[key] !== formDataSaved[key]) {
          if (key === 'wall_height') {
            if (Number(this.formData[key]) !== Number(formDataSaved[key]))
              isChanged = true
          } else
            isChanged = true
        }
      })
      this.rooms?.forEach(item => {
        const newItem = {...item}
        const savedRoom = rooms?.find(room => room.room_id === item?.room_id)
        if (savedRoom?.action_code !== newItem?.action_code)
          isChanged = true
        else {
          roomChangeableFields.forEach(fieldName => {
            if (!isChanged && String(savedRoom[fieldName]) !== String(newItem[fieldName])) {
              if (Number(savedRoom[fieldName]) !== Number(newItem[fieldName]))
                isChanged = true
            }
          })
        }
      })
      return !isChanged
    },
    cancelApproveMode() {
      return !this.isSubmitDisabled && this.isFormChanged
    },
    editedRooms() {
      return this.rooms?.filter(item => !item.hide)
    }
  },
  methods: {
    numberWithCommas,
    async createDS() {
      this.loading = true
      const res = await createRoomChangeDS(this.client_request_id, this.formData)
      this.loading = false
      if (!res) return
      this.showSucceedSnackbar()
      this.sortResponse(res)
      this.updateData && this.updateData()
    },
    async updateDS() {
      const filteredRooms = this.rooms?.filter(item => !(item.new && item.action_code === this.dsRoomActionCodes.deleted))
      const body = {
        ...this.formData,
        ds_room_changes: filteredRooms?.map(item => ({
          room_area: item.room_area,
          room_id: item.room_id,
          action_code: item.action_code
        }))
      }

      this.loading = true
      const res = await updateRoomChangeDS(this.client_request_id, this.data?.ds_id, body)
      this.loading = false
      if (!res) return
      this.showSucceedSnackbar()
      this.sortResponse(res)
      this.updateData && this.updateData()
    },
    async getDS() {
      this.isFetching = true
      const res = await getRoomChangeDSData(this.client_request_id, this.dsData?.ds_id)
      this.isFetching = false
      if (!res) return
      this.sortResponse(res)
    },
    onRoomChange(key, value, room_id) {
      this.rooms = this.rooms?.map(item =>
          item.room_id === room_id ? ({...item, [key]: value}) : item)
    },
    onChange(key, value) {
      this.isFormChanged = true
      this.formData[key] = value || null
    },
    sortResponse(res) {
      const {formData: formDataRes, data, rooms} = res
      const formData = {...this.formData, ...formDataRes}
      this.rooms = rooms
      this.formData = formData
      this.data = this.data ? {...this.data, ...data} : data

      this.savedData = {
        formData: {...this.formData, ...formDataRes},
        rooms
      }
    },
    async actionWithRoom(room_id, action_code, row) {
      const deleted = action_code === this.dsRoomActionCodes.deleted
      if (deleted)
        return await this.restoreRoom(room_id, row.initial_action_code)
      await this.removeRoom(room_id, action_code, row.new)
    },
    async restoreRoom(room_id, initial_action_code) {
      this.rooms = this.rooms.map(item => item.room_id === room_id ? {
        ...item,
        action_code: initial_action_code === this.dsRoomActionCodes.deleted ? this.dsRoomActionCodes.edited : initial_action_code
      } : item)
    },
    async removeRoom(room_id, action_code, isNew) {
      if (isNew)
        return this.rooms = this.rooms?.filter(item => item.room_id !== room_id)
      this.rooms = this.rooms.map(item =>
          item.room_id === room_id ? {
            ...item,
            hide: action_code === this.dsRoomActionCodes.created,
            action_code: this.dsRoomActionCodes.deleted
          } : item)
    },
    onAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddRoom, {
        title: `Добавить комнату`,
        onSubmit: this.addRoom,
        client_request_id: this.client_request_id,
        ds_id: this.data?.ds_id,
        room_ids: this.rooms?.map(item => item.room_id)
      }));
    },
    async addRoom(room) {
      const newRoom = {
        ...room,
        new: true,
        action_code: this.dsRoomActionCodes.created,
        initial_action_code: this.dsRoomActionCodes.created
      }
      const newRoomList = this.rooms ? [...this.rooms, newRoom] : [newRoom]
      this.rooms = newRoomList.sort((a, b) => {
        if (a.order_num < b.order_num) {
          return -1;
        } else if (a.order_num > b.order_num) {
          return 1;
        } else {
          if (a.room_id < b.room_id) {
            return -1;
          } else if (a.room_id > b.room_id) {
            return 1;
          } else {
            return 0;
          }
        }
      })
      this.$store.commit(appActions.closeModal());
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar()
    return {showSucceedSnackbar}
  }
}
</script>

<style scoped lang="scss">
.form {
  &__header {
    background-color: #f5f5f5;
    padding: 15px;

    &_inputs {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 20px;
    }

    &_info {
      margin-top: 20px;

      & > p {
        font-weight: 500;
        margin: 0 0 5px;
      }

      &Flex {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }

      & > div {
        color: #777777;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 1px;

        & span {
          font-size: 14px;
          color: #424242;
          display: block;
          font-weight: 400;
        }
      }
    }

    &_item {
      width: 200px;
      max-width: 200px;

      &.small {
        width: auto;
      }
    }
  }

  &__content {
    padding: 0 15px;
    margin-top: 10px;
    overflow-x: auto;

    & > div {
      padding-bottom: 0;
      border: none;
    }

    &_item {
      display: grid;
      grid-template-columns: 150px 110px 26px;
      width: 320px;
      padding: 10px 0;
      align-items: flex-end;
      gap: 15px;
      border-bottom: 1px solid #e0e0e0;

      &.title {
        font-weight: 700;
        border-bottom: 2px solid #c0c0c0;
        text-align: center;
      }
    }

    &_rooms {
      margin-top: 15px;
    }
  }
}

.room {
  &__new {
    text-transform: uppercase;
    color: #00d500;
    font-size: 11px;
    font-weight: 700;
    margin-left: 2px;
  }

  &__deleted {
    color: #d02222;
    font-size: 17px;
    margin-left: 2px;
  }
}

.iconTrashGreen {
  color: green;

  &:hover {
    color: green;
  }
}
</style>
