<template>

  <CustomTable :hideTopTitle="true" :rowsData="this.changedMaterialsData" :columns="changedMaterialsColumns"
    :loading="isMCFetching" :custom-body="true" :sticky-header="true">
    <template #customBody="props">
      <q-tr :class="getRowClass(props.row.action_type)">
        <q-tooltip v-if="props.row.action_type">
          {{ actionTypeMap[props.row.action_type] }}
        </q-tooltip>
        <custom-table-cell align="center">
          {{ props.row.tk_change_id }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.room_name }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.work_set_name_old }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.material_name_old }}
          <div class="hr hr-0"></div>
          <div align="right">
            {{ props.row.material_old_cnt }}
            {{ props.row.unit_name }}
          </div>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.material_set_name_old }}
        </custom-table-cell>

        <custom-table-cell align="center">
          <CustomImg class="textConstructor__photo" :src="props.row.photo_url_old" alt="material_photo" />
        </custom-table-cell>
        <custom-table-cell align="right">
          <b>
            <Amount :value="props.row.price_old" />
          </b>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.work_set_name_new }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.material_new_name }}
          <div class="hr hr-0"></div>
          <div align="right">
            {{ props.row.material_new_cnt }}
            {{ props.row.unit_name }}
          </div>
        </custom-table-cell>

        <custom-table-cell align="left">
          {{ !!props.row.material_set_name_new ? `${props.row.material_set_new_id} /
          ${props.row.material_set_name_new}` : '' }}


          <div v-if="!!props.row.items_json">
            <q-btn @click="() => this.toggleCollapse(props.row.tk_change_id)" color="primary" size="sm" class="collapseBtn">
              Материалы в наборе ({{ getItemsCount(props.row.items_json) }})</q-btn>
            <CollapseBlock :onChange="() => this.toggleCollapse(props.row.tk_change_id)"
              :collapsedProps="!this.collapsedItems[props.row.tk_change_id]" :controlMode="true" hideExpand>
              <CustomTable :columns="changedMaterialsInnerColumns" :rowsData="props.row.items_json" :small="true"
                :custom-body="true" :dense="true" flat :hideTopTitle="true" :striped="false">
                <template #customBody="innerProps">
                  <q-tr>
                    <custom-table-cell align="center">
                      {{ innerProps.row.material_id }}
                    </custom-table-cell>
                    <custom-table-cell align="left">
                      {{ innerProps.row.material_name }}
                    </custom-table-cell>
                    <custom-table-cell align="center">
                      <CustomImg class="textConstructor__photo" :src="props.row.photo_url" alt="material_photo" />
                    </custom-table-cell>
                    <custom-table-cell align="right">
                      {{ innerProps.row.material_cnt_old }}
                    </custom-table-cell>
                    <custom-table-cell align="right">
                      {{ innerProps.row.material_cnt_new }}
                    </custom-table-cell>
                    <custom-table-cell align="left">
                      {{ innerProps.row.unit_name }}
                    </custom-table-cell>
                  </q-tr>
                </template>
              </CustomTable>
            </CollapseBlock>
          </div>

        </custom-table-cell>
        <custom-table-cell align="center">
          <CustomImg class="textConstructor__photo" :src="props.row.photo_url_new" alt="material_photo" />
        </custom-table-cell>

        <custom-table-cell align="right">
          <b>
            <Amount :value="props.row.price_new" />
          </b>
        </custom-table-cell>
        <custom-table-cell align="right">
          <b>
            <Amount :value="props.row.price_diff" />
          </b>
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.change_date }}
        </custom-table-cell>

        <custom-table-cell align="center">
          <q-btn @click="() => this.handleInfoClick(props.row)" color="primary" label="" size="sm" :round="true"
            icon="info" />
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import { getChangedMaterialsDsData, changedMaterialsColumns, changedMaterialsInnerColumns, getRowClass } from "@/views/ClientRequests/remontDS/dsCreationTemplates/changeTextConstructor/services";

import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomImg from "@/components/common/CustomImg";
import CustomPopup from "@/components/common/CustomPopup";
import PopupConfirm from "@/components/common/PopupConfirm";
import CollapseBlock from "@/components/common/CollapseBlock";
import Amount from "@/components/common/Amount";
import { appActions } from "@/services/store/modules/app_module/app_actions";
import { MODAL_NAMES } from "@/constants";


export default {
  name: "MaterialsChangedBlock",
  components: { CustomImg, CustomTable, CustomTableCell, CustomPopup, PopupConfirm, CollapseBlock, Amount },
  props: ['data', 'client_request_id', 'ds_id'],

  data() {
    return {
      loading: false,
      changedMaterialsColumns,
      changedMaterialsInnerColumns,
      changedMaterialsData: [],
      collapsedItems: {},
      rowsData: this.data,
      data: null,
      isMCFetching: false,
      actionTypeMap: {
        1: "Строка удалена из ТК",
        2: "Строка добавлена",
        3: "Строка изменена",
        4: "Изменены кол-ва",
      },
    }
  },
  methods: {
    getRowClass,
    async getChangedMaterials() {
      this.isMCFetching = true
      const res = await getChangedMaterialsDsData(this.client_request_id, this.ds_id)
      this.isMCFetching = false;
      this.changedMaterialsData = res;
    },
    toggleCollapse(id) {
      this.collapsedItems[id] = !this.collapsedItems[id]
    },
    getItemsCount(items) {
      return items?.length || 0; 
    },

    async handleInfoClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.TEXT_CONSTRUCTOR_INFO, {
        onSubmit: (body) => this.onAddSubmit(body),
        title: `tk_change_id: ${row.tk_change_id}, action_type: ${this.actionTypeMap[row.action_type]} (#${row.action_type})`,
        body: row,
      }));
    },
  },
  mounted() {
    this.getChangedMaterials();
  }
}
</script>

<style scoped lang="scss">
.form {
  &__header {
    background-color: #f5f5f5;
    padding: 15px;

    &_inputs {
      display: grid;
      grid-template-columns: 3fr 2fr 7fr;
      gap: 20px;

      @media (max-width: 768px) {
        grid-template-columns: 12fr;
      }
    }

    &_info {
      margin-top: 20px;

      &>p {
        font-weight: 500;
        margin: 0 0 5px;
      }
    }
  }

  &__content {
    padding: 0 15px;
    margin-top: 10px;

    margin-bottom: 10px;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 3fr 6fr 3fr;
    gap: 20px;

    @media (max-width: 768px) {
      grid-template-columns: 12fr;
    }
  }
}

.manualDsSum {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 3fr 6fr 3fr;
  gap: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: 3fr 9fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 6fr 6fr; 
  }
}

.textConstructor {
  &__header {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-bottom: 20px;
  }

  &__photo {
    max-width: 90px;
    max-height: 90px;
  }

  &__sets {
    margin-top: 3px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &_item {
      padding: 5px 8px;
    }

    &_title {
      font-weight: 500;
    }

    &_label {
      color: #3c69b2;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__star {
    font-size: 20px;
    color: #69AA46;
    margin-right: 2px;
  }
}

.customTabs {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

.hr.hr-0 {
  margin: 3px 0 !important;
}

.hr {
  display: block;
  height: 0;
  overflow: hidden;
  font-size: 0;
  border-width: 1px 0 0;
  border-bottom: 1px dotted rgba(0, 0, 0, .11);
  margin: 12px 0;
}

.row-red {
  background-color: rgba(255, 0, 0, 0.3) !important;
}

.custom-table tbody tr.row-red {
  background-color: rgba(255, 0, 0, 0.3) !important;
}

.row-green {
  background-color: rgba(0, 128, 0, 0.2) !important;
}

.custom-table tbody tr.row-green {
  background-color: rgba(0, 128, 0, 0.2) !important;
}

.row-orange {
  background-color: rgba(255, 166, 0, 0.5) !important;
}

.custom-table tbody tr.row-orange {
  background-color: rgba(255, 166, 0, 0.5) !important;
}

.row-blue {
  background-color: lightblue !important;
}

.custom-table tbody tr.row-blue {
  background-color: lightblue !important;
}

.highlight-input {
  background-color: lightblue;
}

.save-btn {
  background-color: green;
  color: white;
}

.reset-btn {
  background-color: orange;
  color: white;
}

.collapseBtn {
  margin: 10px 0;
}
</style>