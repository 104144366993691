<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">
<br>
      <div v-for="(line, index) in formattedSessionNotice" :key="index">
        {{ line }}
        <div class="hr hr-0"></div>
      </div>
      <CustomModalFooter :hideSubmitBtn="true" :cancelBtnText="'Закрыть'"/>
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";

export default {
  name: "text-constructor-info",
  components: { CustomCheckbox, CustomModalFooter, CustomInput, CustomSelect },
  props: ['data'],
  data() {
    return {
      loading: false,
      isSubmitDisabled: true,
      formData: {
        session_notice: this.data?.body?.session_notice || '',
        city_id: this.data?.body?.city_id || '',
        contragent_type_id: this.data?.body?.contragent_type_id || '',
      },
      cityOptions: [],
      contragentTypeOptions: [],
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },

  },
  computed: {
    formattedSessionNotice() {
      if (!this.formData.session_notice) return [];
      return this.formData.session_notice.split("@").filter(line => line.trim() !== ""); 
    },
  }
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 12fr;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 12fr;
  }

  
}

.hr.hr-0 {
    margin: 3px 0 !important;
}

.hr {
    display: block;
    height: 0;
    overflow: hidden;
    font-size: 0;
    border-width: 1px 0 0;
    border-bottom: 1px dotted rgba(0, 0, 0, .11);
    margin: 12px 0;
}
</style>
