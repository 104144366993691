import { remontRealizationsAPI } from "@/views/RemontRealizations/api";

export const getRemontRealizations = async (filters) => {
  try {
    const res = await remontRealizationsAPI.getList(filters);
    return res?.data;
  } catch (e) {}
};

export const sendTo1C = async (remontRealisationId, isSendTo1C, filters) => {
  try {
    const response = await remontRealizationsAPI.sendTo1C(
      remontRealisationId,
      isSendTo1C,
      filters
    );
    return response?.data;
  } catch (error) {}
};

export const confirmClientInfo = async (remontRealisationId, filters) => {
  try {
    const response = await remontRealizationsAPI.confirmClientInfo(
      remontRealisationId,
      filters
    );
    return response?.data;
  } catch (error) {}
};

export const confirmExpenses = async (remontRealisationId, filters) => {
  try {
    const response = await remontRealizationsAPI.confirmExpenses(
      remontRealisationId,
      filters
    );
    return response?.data;
  } catch (error) {}
};

export const recalculate = async (remontRealisationId, filters) => {
  try {
    const response = await remontRealizationsAPI.recalculate(
      remontRealisationId,
      filters
    );
    return response?.data;
  } catch (error) {
  }
};

export const remontRealizationTableColumns = [
  {
    name: "remont_id",
    label: "ID",
    field: "remont_id",
    sortable: true,
    headerStyle: "width: 80px",
    style: "width: 100px",
    align: "center",
  },
  {
    name: "prop_fio",
    label: "ФИО Клиента",
    field: "prop_fio",
    sortable: true,
    align: "center",
  },
  {
    name: "client_agreement",
    label: "Договор/Тип",
    field: "client_agreement",
    align: "center",
  },
  {
    name: "warranty_date",
    label: "Прием на гарантию",
    field: "warranty_date",
    align: "center",
  },
  {
    name: "sum_without_ds",
    label: "Сумма без ДС",
    field: "sum_without_ds",
    align: "center",
  },
  {
    name: "sum_ds",
    label: "Сумма ДС",
    field: "sum_ds",
    align: "center",
  },
  {
    name: "sum_without_neustoika",
    label: "Итого (без неустойки)",
    field: "sum_without_neustoika",
    align: "center",
  },
  {
    name: "sr_sum",
    label: "Сумма/Доля SmartRemont",
    field: "sr_sum",
    align: "center",
  },
  {
    name: "remont_partner_json",
    label: "Доля партнеров",
    field: "remont_partner_json ",
    align: "center",
  },
  {
    name: "is_send_to_1c_text",
    label: "1C",
    field: "is_send_to_1c_text",
    align: "center",
  },
];

export const detailTableColumns = [
  {
    name: "partner_type_name",
    label: "Тип контрагента",
    field: "partner_type_name",
    align: "center",
  },
  {
    name: "contractor_name",
    label: "Контрагент",
    field: "contractor_name",
    align: "center",
  },
  { name: "spec_name", label: "Договор", field: "spec_name", align: "center" },
  {
    name: "contractor_sum",
    label: "Сумма/Доля",
    field: "contractor_sum",
    align: "center",
  },
  { name: "is_payed", label: "Оплачено", field: "is_payed", align: "center" },
  {
    name: "is_payed_percent",
    label: "% оплаченной суммы",
    field: "is_payed_percent",
    align: "center",
  },
  {
    name: "is_not_payed",
    label: "Ожидается",
    field: "is_not_payed",
    align: "center",
  },
  {
    name: "is_not_payed_percent",
    label: "% неоплаченной суммы",
    field: "is_not_payed_percent",
    align: "center",
  },
];
