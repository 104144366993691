<template>
  <FiltersWrapper>
    <CollapseBlock class="filters" tablet-mode>
      <div class="filters__item">
        <CustomSelect
          :options="this.companies"
          :value="filters.company_id"
          label="Компания"
          :required="true"
          option-label-key="company_name"
          option-value-key="company_id"
          @change="value => this.onChange('company_id', value)"
        />
      </div>
      <div class="filters__item">
        <CustomSelect
          :options="this.mainContractors"
          :value="filters.contractor_main_id"
          label="Главный подрядчик"
          option-label-key="contractor_main_name"
          option-value-key="contractor_main_id"
          @change="value => this.onChange('contractor_main_id', value)"
          title="Выберите главного подрядчика для просмотра тарифов и вложенных работ"
        />
      </div>
      <div class="filters__item">
        <CustomSelect
          :options="this.workSetGroupings"
          :value="filters.grouping_by"
          label="Группировать по"
          :required="true"
          :clearable="false"
          option-label-key="label"
          option-value-key="id"
          @change="value => this.onChange('grouping_by', value)"
        />
      </div>
      <div class="filters__item">
        <CustomInput
          type="number"
          label="ID ремонта"
          :value="filters.remont_id"
          @change_input="(value) => this.onChange('remont_id', value)"
          title="Ремонт, по которому хотим посмотреть все работы"
        />
      </div>
      <div class="filters__item">
        <CustomSelect
          :options="this.remontStatuses"
          :value="filters.remont_status"
          label="Статус ремонта"
          :clearable="false"
          option-label-key="label"
          option-value-key="id"
          @change="value => this.onChange('remont_status', value)"
          title="Работы, которые используются в активных ремонтах. Либо работы, которые в активных ремонтах не используются."
        />
      </div>
      <div class="filters__item">
        <CustomSelect
          :options="this.workSetTypes"
          :value="filters.work_set_type"
          :clearable="false"
          label="Тип конструктива"
          option-label-key="label"
          option-value-key="id"
          @change="value => this.onChange('work_set_type', value)"
          title="Посмотреть какие контструктивы вложенные, какие основные"
        />
      </div>
      <div class="filters__item">
        <CustomSelect
          :options="this.nestedWorkSets"
          label="Вложенный конструктив"
          optionValueKey="work_set_id"
          optionLabelKey="work_set_name"
          :value="this.filters.nested_work_set_id || ''"
          @change="(value) => onChange('nested_work_set_id', value)"
          title="В каких основных конструктивах встречается вложенный конструктив"
        />
      </div>
      <q-btn
        type="submit"
        @click="() => this.getData(this.filters)"
        :loading="this.isFetching"
        size="sm"
        no-wrap
        color="primary"
      >
        <q-icon name="search" />
        Поиск
      </q-btn>
    </CollapseBlock>
  </FiltersWrapper>
</template>

<script>
import CustomSelect from "@/components/form/CustomSelect";
import CustomInput from "@/components/form/CustomInput";
import {workSetTypes, workSetGroupings, remontStatuses, getFiltersMetaData} from './services'
import CollapseBlock from "@/components/common/CollapseBlock";
import FiltersWrapper from "@/components/common/FiltersWrapper";

export default {
  name: "WorkSetFilters",
  components: {CustomSelect, CustomInput, CollapseBlock, FiltersWrapper},
  props: ['getData', 'isFetching', 'filters', 'onChange'],
  data() {
    return {
      workSetTypes, workSetGroupings, remontStatuses,
      mainContractors: [],
      nestedWorkSets: [],
    }
  },
  computed: {
    companies() {
      return this.$store.state.app?.companies
    },
  },
  mounted() {
    this.getContractors()
  },
  methods: {
    async getContractors() {
      const res = await getFiltersMetaData()
      if(!res) return
      const [mainContractors, workSets] = res
      this.mainContractors = mainContractors || []
      this.nestedWorkSets = workSets || []
    }
  }
}
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;

  &__item {
    min-width: 150px;
  }
}
</style>
