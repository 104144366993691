<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomSelect
          :options="this.workSets"
          label="Конструктив"
          optionValueKey="contractor_work_set_id"
          optionLabelKey="work_set_name"
          :value="this.formData.contractor_work_set_chid || ''"
          @change="(value) => onChange('contractor_work_set_chid', value)"
          :required="true"
        />
      </div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import { getContractorWorkSets } from "@/services/api/api";

export default {
  name: "add-hidden-workset",
  components: {CustomInput, CustomModalFooter, CustomSelect},
  props: ['data'],
  data() {
    return {
      workSets: [],
      loading: false,
      formData: {
        contractor_work_set_chid: this.data?.body?.contractor_work_set_chid || '',
      }
    }
  },
  methods: {
    async getData() {
      const res = await getContractorWorkSets(this.data.contractor_work_set_id)
      this.workSets = res || []
    },
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  width: 350px;
  gap: 15px;
  margin-top: 10px;

  @media (max-width: 428px) {
    width: 100%;
  }
}
.row {
  display: flex;
  align-items: center;
  gap: 15px;
  @media (max-width: 428px) {
    flex-direction: column;
  }
}
</style>
