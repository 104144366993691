import instance from "@/services/api";

export const remontRealizationsAPI = {
  async getList(filters = {}) {
    return await instance()
      .get("/finance/realisation/read/", {params: filters}) 
      .then((res) => res?.data);
  },
  
  async sendTo1C(remontRealisationId, isSendTo1C, params) {
    return await instance().post("/finance/realisation/send_to_1c/", {
      remont_realisation_id: remontRealisationId,
      is_send_to_1c: isSendTo1C,
    }, {params}).then((res) => res?.data);
  },
  
  async confirmClientInfo(remontRealisationId, params) {
    return await instance().post("/finance/realisation/info_accept/", {
      remont_realisation_id: remontRealisationId,
      what: "IS_ACCEPT_CLIENT_INFO",
    }, {params}).then((res) => res?.data);
  },
  
  async confirmExpenses(remontRealisationId, params) {
    return await instance().post("/finance/realisation/info_accept/", {
      remont_realisation_id: remontRealisationId,
      what: "IS_ACCEPT_EXPENSE",
    }, {params}).then((res) => res?.data);
  },
  
  async recalculate(remontRealisationId, params) {
    return await instance().post("/finance/realisation/recount/", {
      remont_realisation_id: remontRealisationId,
    }, {params}).then((res) => res?.data);
  },
};
