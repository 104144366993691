import instance from "@/services/api";

export const textConstructorDsAPI = {
  async getTextConstructorMaterials(client_request_id, ds_id) {
    return await instance()
      .get(`/client_request/${client_request_id}/ds/${ds_id}/tk_material/`)
      .then((res) => res?.data);
  },

  async getChangedMaterials(client_request_id, ds_id) {
    return await instance()
      .get(`/client_request/${client_request_id}/ds/${ds_id}/tk_change/`)
      .then((res) => res?.data);
  },

  async getRooms(client_request_id) {
    return await instance()
      .get(`/client_request/${client_request_id}/ds/request_read_request_room/`)
      .then((res) => res?.data);
  },

  async getWorkSets(client_request_id, room_id) {
    return await instance()
      .get(`/client_request/${client_request_id}/ds/${room_id}/read_ds_tk_work_set/`)
      .then((res) => res?.data);
  },

  async addWork(client_request_id, body) {
    return await instance()
      .post(`/client_request/${client_request_id}/ds/tk_ds_modify_work/`, body)
      .then((res) => res?.data);
  },

  async getMaterials(client_request_id, body) {
    return await instance()
      .post(`/client_request/${client_request_id}/ds/read_material_for_tk_change/`, body)
      .then((res) => res?.data);
  },

  async getRoomsMC(client_request_id, body) {
    return await instance()
      .post(`/client_request/${client_request_id}/ds/read_ds_tk_room/`, body)
      .then((res) => res?.data);
  },

  async getWorkSetsMC(client_request_id, body) {
    return await instance()
      .post(`/client_request/${client_request_id}/ds/read_ds_tk_material_work_set/`, body)
      .then((res) => res?.data);
  },

  async getMaterialSet(client_request_id, body) {
    return await instance()
      .post(`/client_request/${client_request_id}/ds/read_ds_tk_material_set/`, body)
      .then((res) => res?.data);
  },

  async addMaterial(client_request_id, body) {
    return await instance()
      .post(`/client_request/${client_request_id}/ds/tk_ds_modify_material/`, body)
      .then((res) => res?.data);
  },

  async modifyCancel(client_request_id, client_material_id) {
    return await instance()
      .post(`/client_request/${client_request_id}/ds/${client_material_id}/tk_ds_modify_cancel/`)
      .then((res) => res?.data);
  },

  async deleteMaterial(client_request_id, body) {
    return await instance()
      .post(`/client_request/${client_request_id}/ds/tk_ds_delete/`, body)
      .then((res) => res?.data);
  },

  async deleteCancel(client_request_id, body) {
    return await instance()
      .post(`/client_request/${client_request_id}/ds/tk_change_delete_cancel/`, body)
      .then((res) => res?.data);
  },

  async saveMaterialCount(client_request_id, body) {
    return await instance()
      .post(`/client_request/${client_request_id}/ds/tk_change_set_item_cnt/`, body)
      .then((res) => res?.data);
  },
};
