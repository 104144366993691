<template>
  <q-select
    :class="['custom-select', {'custom-select__hideInput': !!this.value && !this.multiple, 'custom-select__fullWidth': !!this.fullWidth}]"
    :options="filteredOptions"
    v-model="model"
    :label="`${this.label || 'Выберите'} ${this.required ? '*' : ''}`"
    :use-input="this.useInput !== false"
    :dense="true"
    :map-options="true"
    ref="select"
    :multiple="this.multiple"
    :options-dense="true"
    :loading="this.loading"
    :option-value="this.optionValueKey"
    :option-label="this.optionLabelKey || ''"
    dropdown-icon="expand_more"
    @filter="filterFn"
    :rules="this.rules"
  >
    <template
      v-slot:selected="scope"
      v-if="this.multiple && (this.computedOptions?.length - 1) === this.value?.length"
    >
      <div v-if="this.value?.length">Все</div>
    </template>
    <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
      <q-item v-bind="itemProps">
        <q-item-section>
          <q-item-label
            :style="opt.bold ? 'font-weight: bold' : ''"
            v-html="opt.label"
          />
        </q-item-section>
        <q-item-section side v-if="this.multiple">
          <q-checkbox
            :model-value="opt[this.optionValueKey] === 'ALL' ? (this.computedOptions?.length - 1) === this.value?.length : selected"
            @update:model-value="toggleOption(opt)"
          />
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">{{this.noOptionText || 'Не найдено'}}</q-item-section>
      </q-item>
    </template>
    <template v-slot:default v-if="!!this.title">
      <q-tooltip>{{this.title}}</q-tooltip>
    </template>
    <template v-if="value" v-slot:append>
      <q-icon
        name="cancel"
        v-if="(this.multiple ? !!this.value?.length : !!value) && this.clearable !== false"
        @click.stop.prevent="onChange(this.multiple ? [] : '')"
        class="cursor-pointer custom-select__icon"
      />
    </template>
  </q-select>
</template>

<script>
export default {
  name: "CustomSelect",
  props: [
    "options",
    "value",
    "onChange",
    "useInput",
    "label",
    "required",
    "triggerValidationOnMount",
    "multiple",
    "loading",
    "optionLabelKey",
    "optionValueKey",
    "filterFunc",
    "fullWidth",
    "clearable",
    "title",
    "noOptionText",
  ],
  data() {
    return {
      searchTerm: "",
    };
  },
  mounted() {
    if (this.triggerValidationOnMount)
      this.$refs.select.validate()
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(row) {
        let value = row[this.optionValueKey]
        if (this.multiple) {
          if (row?.length) {
            let newValue = row[row?.length - 1][this.optionValueKey]
            if (newValue === 'ALL') {
              const checked = row?.length === this.computedOptions?.length
              if (checked)
                value = []
              else
                value = this.computedOptions?.map(item => item[this.optionValueKey])?.filter(item => item !== 'ALL')
            } else
              value = row?.map(item => typeof item === 'object' ? item[this.optionValueKey] : item)
          } else
            value = []
        }
        this.$emit("change", value, row);
      },
    },
    computedOptions() {
      let options = this.options?.map(item => ({...item, label: item[this.optionLabelKey]}));
      if (this.multiple && options)
        options = [{label: "Выбрать все", [this.optionLabelKey]: 'Все', [this.optionValueKey]: 'ALL'}, ...options]
      return options
    },
    filteredOptions() {
      if (!this.searchTerm) return this.computedOptions
      return this.computedOptions?.filter((item) => {
            return item[this.optionLabelKey]?.toLowerCase().includes(this.searchTerm?.toLowerCase())
          }
      );
    },
    rules() {
      const rules = []
      if (this.required) {
        const reqFunc = val => (!!val) || 'Это поле обязательно'
        rules.push(reqFunc)
      }
      return rules
    },
  },
  methods: {
    filterFn(val, update) {
      update(() => {
        this.searchTerm = val;
      });
      if (!!this.filterFunc) {
        this.filterFunc(val, update)
      }
    },
  },
};
</script>

<style lang="scss">
.custom-select {
  font-size: 14px;
  padding-bottom: 0 !important;

  &__hideInput .q-field__input {
    display: none !important;
  }

  & .q-field__native span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .q-field__bottom {
    padding-top: 3px !important;
  }

  &__icon {
    font-size: 19px !important;
  }

  &__fullWidth {
    width: 100%;
  }
}
</style>
