<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomSelect :options="this.materialsOptions" label="Материал" :required="true" optionValueKey="material_id"
          optionLabelKey="material_name" :value="this.formData.material_id || ''
            " @change="(value) => {
            onChange('material_id', value);
          }" />
      </div>

      <div class="form__item">
        <CustomSelect :options="roomsOptions" label="Комната" :required="true" optionValueKey="room_id" :disable="!!this.formData.client_material_id" 
          optionLabelKey="room_name" :value="this.formData.room_id || ''" @change="(value) => {
            onChange('room_id', value);
          }" noOptionText="Выберите материал"/>
      </div>

      <div class="form__item">
        <CustomSelect :options="workSetsOptions" label="Конструктив" :required="true" optionValueKey="work_set_id" 
          optionLabelKey="work_set_name" :value="this.formData.work_set_id || ''" @change="(value) => {
            onChange('work_set_id', value);
            
          }" />
      </div>

      <div class="form__item">
        <CustomSelect :options="materialsSetOptions" label="Набор" optionValueKey="material_set_id"
          optionLabelKey="material_set_name" :value="this.formData.material_set_id || ''"
          @change="(value) => onChange('material_set_id', value)" />
      </div>
      <div></div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import {
  getMCMaterials,
  getMCRooms,
  getMCWorkSets,
  getMCMaterialSets,
} from '@/views/ClientRequests/remontDS/dsCreationTemplates/changeTextConstructor/services'

export default {
  name: "text-constructor-materials",
  components: { CustomCheckbox, CustomModalFooter, CustomInput, CustomSelect },
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        client_material_id: this.data?.body?.client_material_id || '',
        client_request_id: this.data?.body?.client_request_id || '',
        ds_id: this.data?.body?.ds_id || '',
        material_id: this.data?.body?.material_id || '',
        room_id: this.data?.body?.room_id || '',
        work_set_id: this.data?.body?.work_set_id || '',
        material_set_id: this.data?.body?.material_set_id || '',
      },
      materialsOptions: [],
      roomsOptions: [],
      workSetsOptions: [],
      materialsSetOptions: [],
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value;
      if (key === 'material_id' && !this.formData.client_material_id) {
        this.formData.room_id = '';
        this.formData.work_set_id = '';
        this.formData.material_set_id = '';
        this.roomsOptions = [];
        this.workSetsOptions = [];
        this.materialsSetOptions = [];
        this.fetchRooms(this.formData.client_request_id, this.formData.material_id);
      } 
        if (key === 'material_id' && !!this.formData.client_material_id) {
        this.formData.work_set_id = '';
        this.formData.material_set_id = '';
        this.workSetsOptions = [];
        this.materialsSetOptions = [];
        this.fetchRooms(this.formData.client_request_id, this.formData.material_id);
        this.fetchWorkSets(this.formData.client_request_id, this.formData.material_id, this.formData.room_id, this.formData.client_material_id);
        this.fetchMaterialSets(this.formData.client_request_id, this.formData.material_id, this.formData.room_id, this.formData.work_set_id);
      } else if (key === 'room_id') {
        this.formData.work_set_id = '';
        this.formData.material_set_id = '';
        this.workSetsOptions = [];
        this.materialsSetOptions = [];
        this.fetchWorkSets(this.formData.client_request_id, this.formData.material_id, this.formData.room_id, this.formData.client_material_id);
      } else if (key === 'work_set_id') {
        this.formData.material_set_id = '';
        this.materialsSetOptions = [];
        this.fetchMaterialSets(this.formData.client_request_id, this.formData.material_id, this.formData.room_id, this.formData.work_set_id);
      }
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async fetchMaterials(client_request_id, client_material_id) {
      const body = { client_material_id }
      this.materialsOptions = await getMCMaterials(client_request_id, body);
    },
    async fetchRooms(client_request_id, material_id) {
      const body = { material_id }
      this.roomsOptions = await getMCRooms(client_request_id, body);
    },
    async fetchWorkSets(client_request_id, material_id, room_id, client_material_id) {
      const body = { material_id, room_id, client_material_id }
      this.workSetsOptions = await getMCWorkSets(client_request_id, body);
    },
    async fetchMaterialSets(client_request_id, material_id, room_id, work_set_id) {
      const body = { material_id, room_id, work_set_id }
      this.materialsSetOptions = await getMCMaterialSets(client_request_id, body);
    },
  },
  mounted() {
    this.fetchMaterials(this.formData.client_request_id, this.formData.client_material_id);
    if (!!this.formData.client_material_id) {
      this.fetchRooms(this.formData.client_request_id, this.formData.material_id);
      this.fetchWorkSets(this.formData.client_request_id, this.formData.material_id, this.formData.room_id, this.formData.client_material_id)
      this.fetchMaterialSets(this.formData.client_request_id, this.formData.material_id, this.formData.room_id, this.formData.work_set_id)
    }
  }
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  min-width: 900px;
  grid-template-columns: 12fr;
  gap: 20px;
  
  @media (max-width: 991px) {
    min-width: 50%;
    max-width: 100%;
    grid-template-columns: 12fr;
  }
}
</style>
