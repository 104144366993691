export const filteredRowsData = (
  columns,
  rowsData,
  searchTerm,
  keysProp = null
) => {
  let keys =
    keysProp ||
    columns?.map((item) => {
      return item?.field === item.name ? item.name : item.field;
    });
  keys = keys.filter(
    (item) => !!item && (typeof item === "number" || typeof item === "string")
  );
  return rowsData?.filter((item) => {
    return !!keys.find((key) => {
      return String(item[key]).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });
};

export const filteredGroupRowsData = (
  columns,
  rowsData,
  searchTerm,
  groupProps = { groupNameProp: "", groupChildrenProp: "", childrenKeys: null }
) => {
  const groupFiltered = filteredRowsData(null, rowsData, searchTerm, [
    groupProps.groupNameProp,
  ]);
  const filtered = rowsData?.map((item) => {
    const isExist = groupFiltered?.find(
      (group) =>
        item[groupProps.groupNameProp] === group[groupProps.groupNameProp]
    );
    return isExist
      ? item
      : {
          ...item,
          [groupProps.groupChildrenProp]: filteredRowsData(
            columns,
            item[groupProps.groupChildrenProp],
            searchTerm,
            groupProps.childrenKeys
          ),
        };
  });
  return filtered?.filter(
    (item) => !!item[groupProps.groupChildrenProp]?.length
  );
};
