import { remontResponsibleAPI } from "@/components/modal/modals/change-remont-responsible/api";
import { AppAPI } from "./app_api";

export const getMainContractors = async () => {
  try {
    const res = await remontResponsibleAPI.getMainContractors();
    return res?.data;
  } catch (e) {}
};
export const getWorkSetList = async () => {
  try {
    const res = await AppAPI.getWorkSets();
    return res?.data;
  } catch (e) {}
};
export const getContractorWorkSets = async (contractor_work_set_id) => {
  try {
    const res = await AppAPI.getContractorWorkSets({ contractor_work_set_id });
    return res?.data;
  } catch (e) {}
};
