<template>
  <q-linear-progress indeterminate v-if="this.isFetching" />
  <CreateDSTemplate
    cancelBtnText="Закрыть"
    :submitBtnText="this.dsData?.ds_id ? 'Сохранить' : 'Создать'"
    :disabled="this.isSubmitDisabled"
    :cancelApproveMode="this.cancelApproveMode"
    :on-submit="this.dsData?.ds_id ? this.updateDS : this.updateDS"
    :on-cancel="this.onCancel"
    :loading="this.loading"
    :label="this.dsData?.ds_type_name"
  >
    <div class="form">
      <div class="form__header">
        <div class="form__header_inputs">
          <div class="form__header_item">
            <CustomDate
              :full-width="true"
              :disable-past="true"
              placeholder="Дата"
              :value="this.formData.ds_date || ''"
              @change_date="(value) => onChange('ds_date', value)"
            />
          </div>
          <div class="form__header_item">
            <CustomCheckbox
              label="Сумма ДС вручную"
              v-model="this.formData.is_zero_sum"
              :value="formData.is_zero_sum"
              :true-value="1"
              :false-value="0"
              @change="onManualSumChange"
              @change_input="(value) => onChange('is_zero_sum', value)"
            />
          </div>

          <div class="form__header_item manualDsSum">
            <div class="form__header_item">
              <CustomInput
                :autoMinWidth="true"
                label="Сумма ДС"
                type="number"
                v-model="this.formData.ds_sum"
                :disable="!manualSum"
                @change_input="(value) => onChange('ds_sum', value)"
              />
            </div>
            <div class="form__header_item">
              <CustomInput
                :required="true"
                :autoMinWidth="true"
                label="Комментарий для ДС с ручной стоимостью"
                type="text"
                v-model="this.formData.zero_sum_comment"
                :disable="!manualSum"
                @change_input="(value) => onChange('zero_sum_comment', value)"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="form__content">
        <div class="form__content_item">
          <span class="header-span">Реквизиты старого клиента</span>
          <CustomInput
            label="ФИО собственника квартиры"
            v-model="this.formData.old_prop_fio"
            :disable="true"
          />
          <CustomInput
            type="number"
            label="ИИН собственника квартиры"
            v-model="this.formData.old_prop_iin"
            :disable="true"
          />
          <CustomInput
            label="Номер документа"
            v-model="this.formData.old_prop_doc_num"
            :disable="true"
          />
          <CustomInput
            label="Кем выдано"
            v-model="this.formData.old_prop_doc_issued"
            :disable="true"
          />
          <div class="custom_date">
            <span class="disabled-span">От какой даты документ</span>
            <CustomDate
              :fullWidth="true"
              label="От какой даты документ"
              :value="this.formData.old_prop_doc_date"
              :disabled="true"
            />
          </div>
          <CustomInput
            label="Адрес"
            type="textarea"
            v-model="this.formData.old_prop_adress"
            :disable="true"
          />
          <CustomInput
            label="Телефон - логин клиента"
            v-model="this.formData.old_prop_phone"
            :disable="true"
          />
          <CustomInput
            label="Email"
            v-model="this.formData.old_email"
            :disable="true"
          />
        </div>

        <div class="form__content_item">
          <span class="header-span">Реквизиты нового клиента</span>
          <CustomInput
            label="ФИО собственника квартиры"
            v-model="this.formData.new_prop_fio"
          />
          <CustomInput
            type="number"
            label="ИИН собственника квартиры"
            v-model="this.formData.new_prop_iin"
          />
          <CustomInput
            label="Номер документа"
            v-model="this.formData.new_prop_doc_num"
          />
          <CustomInput
            label="Кем выдано"
            v-model="this.formData.new_prop_doc_issued"
          />
          <div class="custom_date">
            <span>От какой даты документ:</span>
            <CustomDate
              :fullWidth="true"
              placeholder="От какой даты документ"
              :value="this.formData.new_prop_doc_date"
              @change_date="(value) => onChange('new_prop_doc_date', value)"
            />
          </div>
          <CustomInput
            label="Адрес"
            type="textarea"
            v-model="this.formData.new_prop_adress"
          />
          <CustomInput
            label="Телефон - логин клиента"
            v-model="this.formData.new_prop_phone"
          />
          <CustomInput label="Email" v-model="this.formData.new_email" />
        </div>
      </div>
    </div>
  </CreateDSTemplate>
</template>

<script>
import CreateDSTemplate from "@/views/ClientRequests/remontDS/dsCreationTemplates/template";
import { createDS, updateDS, getDSData } from "@/views/ClientRequests/remontDS/services";
import CustomInput from "@/components/form/CustomInput";
import CustomInputNumber from "@/components/form/CustomInputNumber";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomDate from "@/components/form/CustomDate";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "ClientChange",
  components: { CustomInput, CustomInputNumber, CustomCheckbox, CustomDate, CreateDSTemplate },
  props: ['onCancel', 'updateData', 'client_request_id', 'dsData'],
  data() {
    return {
      manualSum: false,
      isFetching: false,
      loading: false,
      isFormChanged: !!this.dsData?.ds_id,
      data: null,
      formData: {
        ds_id: this.dsData?.ds_id || null,
        ds_type_id: this.dsData?.ds_type_id,
        ds_sum: this.dsData?.ds_sum,
        is_zero_sum: this.dsData?.is_zero_sum || 0,
        zero_sum_comment: this.dsData?.zero_sum_comment,
        ds_date: new Date().toLocaleDateString('ru'),
        old_prop_fio: this.dsData?.old_prop_fio,
        old_prop_iin: this.dsData?.old_prop_iin,
        old_prop_doc_num: this.dsData?.old_prop_doc_num,
        old_prop_doc_issued: this.dsData?.old_prop_doc_issued,
        old_prop_doc_date: this.dsData?.old_prop_doc_date,
        old_prop_adress: this.dsData?.old_prop_adress,
        old_prop_phone: this.dsData?.old_prop_phone,
        old_email: this.dsData?.old_email,

        new_prop_fio: this.dsData?.new_prop_doc_date,
        new_prop_iin: this.dsData?.new_prop_doc_date,
        new_prop_doc_num: this.dsData?.new_prop_doc_date,
        new_prop_doc_issued: this.dsData?.new_prop_doc_date,
        new_prop_doc_date: this.dsData?.new_prop_doc_date,
        new_prop_adress: this.dsData?.new_prop_doc_date,
        new_prop_phone: this.dsData?.new_prop_doc_date,
        new_email: this.dsData?.new_prop_doc_date,
      },
    };
  },
  computed: {
    isSubmitDisabled() {
      if (!this.savedData) return false
    },
    cancelApproveMode() {
      return !this.isSubmitDisabled && this.isFormChanged
    },
  },
  methods: {
    async createDS(onMount = false) {
      if (this.formData.ds_id) return
      this.loading = true;
      try {
        const body = onMount ? { ds_type_id: this.dsData?.ds_type_id } : this.formData
        const res = await createDS(this.client_request_id, body);

        this.loading = false;
        if (res) {
          if (onMount) {
            this.formData = { ...this.formData, ...res.header, ...res.data }
          } else {
            this.updateData && this.updateData();
            this.showSucceedSnackbar();
          }
        } else {
        }
      } catch (error) {
        this.loading = false;
      }
    },
    async updateDS() {
      this.loading = true;
      const res = await updateDS(this.client_request_id, this.formData.ds_id, this.formData);
      this.loading = false;
      if (res) {
        this.updateData && this.updateData();
        this.showSucceedSnackbar();
      }
    },
    async getDS() {
      this.isFetching = true
      const res = await getDSData(this.client_request_id, this.dsData?.ds_id)
      this.isFetching = false
      if (!res) return
      this.formData = { ...this.formData, ...res.header, ...res.data }
    },
    onChange(key, value) {
      this.isFormChanged = true
      this.formData[key] = value || null
    },
    onManualSumChange(value) {
      this.manualSum = value;
      if (!this.manualSum) {
        this.formData.zero_sum_comment = '';
      }
    }
  },
  mounted() {
    if (this.dsData?.ds_id)
      this.getDS()
    else
      this.createDS(true)
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar()
    return { showSucceedSnackbar }
  }
};
</script>

<style scoped lang="scss">
.form {
  &__header {
    background-color: #f5f5f5;
    padding: 15px;

    &_inputs {
      display: grid;
      grid-template-columns: 3fr 2fr 7fr;
      gap: 20px;

      @media (max-width: 768px) {
        grid-template-columns: 12fr;
      }
    }

    &_info {
      margin-top: 20px;

      &>p {
        font-weight: 500;
        margin: 0 0 5px;
      }
    }
  }

  &__content {
    margin: 25px 250px 25px 0px;
    padding: 0 15px;
    @media (max-width: 1024px) {
      margin: 25px 25px 25px 0px;
    }
    margin-bottom: 10px;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 6fr 6fr;
    gap: 50px;

    @media (max-width: 900px) {
      grid-template-columns: 6fr 6fr;
    }

    @media (max-width: 576px) {
      grid-template-columns: 12fr;
    }
  }


  .disabled-span {
    color: #9e9e9e;
    cursor: not-allowed;
    opacity: 0.6;
  }

  .custom_date {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .manualDsSum {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 3fr 6fr 3fr;
    gap: 20px;

    @media (max-width: 1024px) {
      grid-template-columns: 3fr 9fr;
    }

    @media (max-width: 768px) {
      grid-template-columns: 6fr 6fr;
    }
  }
}
.header-span {
  font-weight:bold;
  font-size: large;
}
</style>
