<template>
  <h3 class="title">Список отчетов</h3>
  <q-linear-progress indeterminate color="primary" v-if="this.isFetching"/>
  <NotFoundText v-else-if="!this.reports?.length"/>
  <div v-else>
    <div class="search">
      <CustomInput :value="this.searchTerm" :isSearch="true" label="Поиск" v-debounce:300="this.onSearchChange"/>
    </div>
    <div :style="this.getContentMaxHeight()" @scroll="this.onScroll" ref="reportGeneratorContent">
      <q-tree
          :nodes="filteredReports" class="tree"
          no-connectors no-nodes-label="Ничего не найдено"
          selected-color="primary" :expanded="this.expanded"
          node-key="report_id" label-key="report_name" children-key="child_reports"
      >
        <template v-slot:default-header="props">
          <div
              :class="['row items-center tree__item_wrapper', {tree__item_wrapperEmpty: props?.node?.child_reports?.length === 0 }]"

              @click="this.onExpand(props.node?.report_id)">
          <span
              :class="['tree__item', {tree__item_child: !!props.node?.report_pid }]"
              @click="() => onChange(props.node)">{{ props.node?.report_name }}</span>
          </div>
        </template>
      </q-tree>
    </div>
  </div>
</template>

<script>
import NotFoundText from "@/components/common/NotFoundText";
import {getGeneratorReports} from "@/views/ReportGenerator/services";
import {searchTreeItems} from "@/services";
import CustomInput from "@/components/form/CustomInput";
import Clickable from "@/components/common/Clickable";

export default {
  name: "ReportGeneratorList",
  components: {Clickable, CustomInput, NotFoundText},
  props: ['onChange', 'selected', 'scrollTop', 'searchTerm', 'onSearchChange', 'reports', 'expanded', 'onExpand'],
  data() {
    return {
      isFetching: false,
    }
  },
  mounted() {
    this.scrollToMyDiv()
    if (!this.reports?.length)
      this.getData()
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getGeneratorReports()
      this.isFetching = false
      if (!res) return
      const {reports, ids} = res
      this.$emit('setReports', reports, ids)
    },
    onScroll(e) {
      this.$emit('contentScroll', e.target?.scrollTop)
    },
    getContentMaxHeight() {
      return `max-height: ${window.innerHeight - 220}px;overflow-y: auto`
    },
    scrollToMyDiv() {
      if (!this.scrollTop) return
      if (this.$refs.reportGeneratorContent)
        this.$refs.reportGeneratorContent.scrollTo({
          top: this.scrollTop,
          behavior: 'smooth',
        });
    },
  },
  computed: {
    filteredReports() {
      return searchTreeItems(this.reports, this.searchTerm, 'child_reports', ['report_name'])
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  margin: 0 0 15px;
  font-size: 24px;
}

.tree {
  &__item {
    cursor: pointer;
    font-size: 15px;
    transition: all .3s;
    position: absolute;
    width: 100%;
    padding-left: 25px;
    top: -10px;
    left: -20px;
    min-height: 23px;

    &_wrapper {
      position: relative;
      width: 100%;
      margin-top: -3px;

      &Empty {
        min-height: 9px;
        margin-top: 5px;
      }
    }

    &_child {
      position: unset;
      padding-left: 0;
      top: unset;
      left: unset;

      &:hover {
        text-decoration: underline;
        color: #2680e5;
      }
    }

    &_selected {
      color: #2680e5;
    }
  }
}

.search {
  width: 250px;
  margin-bottom: 15px;
}
</style>