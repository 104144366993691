<template>
  <CustomTable
    title=""
    :rowsData="this.rowsData"
    :columns="this.workSetPriceHistoryColumns"
    :loading="isFetching"
    :custom-body="true"
    :small="true"
    :show-search="false"
    :sticky-header="false"
  >
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center" verticalAlign="center">
          {{props.row?.contractor_work_set_tariff_id}}
        </custom-table-cell>

        <custom-table-cell align="right" verticalAlign="center">
          <Amount :value="props.row?.contractor_work_set_price" />
        </custom-table-cell>
        <custom-table-cell align="center" verticalAlign="center">
          {{props.row.valid_date}}
        </custom-table-cell>
        <custom-table-cell align="center" vertical-align="center">
          <CustomIcon
            icon="trash"
            approveMode
            :approve="() => this.deletePrice(props.row?.contractor_work_set_tariff_id)"
          />
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import Amount from "@/components/common/Amount";
import CustomIcon from "@/components/common/CustomIcon";
import CustomPopup from "@/components/common/CustomPopup";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import { deleteWorkSetPrice, getWorkSetPriceHistory } from "@/views/handbook/WorkSets/services";
import { workSetPriceHistoryColumns } from './services';

export default {
  name: "WorkSetPriceHistory",
  components: {CustomIcon, CustomTableCell, CustomTable,CustomPopup,Amount},
  props: ['contractor_work_set_id', 'filters', 'onDataChange'],
  data() {
    return {
      rowsData: [],
      isFetching: false,
      workSetPriceHistoryColumns
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getWorkSetPriceHistory(this.contractor_work_set_id)
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    async deletePrice(contractor_work_set_tariff_id) {
      const res = await deleteWorkSetPrice(contractor_work_set_tariff_id, this.filters)
      if(!res) return
      this.onDataChange(res?.data)
      this.rowsData = res?.history || []
      this.showSucceedSnackbar()
    }
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss"></style>
