import { getMainContractors, getWorkSetList } from "@/services/api/api";
import { handbookWorksetsAPI } from "@/views/handbook/WorkSets/api";

export const getHandbookWorksets = async (filters) => {
  try {
    const res = await handbookWorksetsAPI.getList(filters);
    return res?.data;
  } catch (e) {}
};
export const getWorksetCheckGroups = async () => {
  try {
    const res = await handbookWorksetsAPI.getWorksetCheckGroups();
    return res?.data;
  } catch (e) {}
};
export const getSpecialities = async () => {
  try {
    const res = await handbookWorksetsAPI.getSpecialities();
    return res?.data;
  } catch (e) {}
};
export const updateHandbookWorkset = async (work_set_id, body, params = {}) => {
  try {
    const res = await handbookWorksetsAPI.updateHandbookWorkset(
      work_set_id,
      body,
      params
    );
    return res?.data;
  } catch (e) {}
};
export const addWorkSetPrice = async (body, params) => {
  try {
    const res = await handbookWorksetsAPI.addPrice(body, params);
    return res?.data;
  } catch (e) {}
};
export const deleteWorkSetPrice = async (
  contractor_work_set_tariff_id,
  params
) => {
  try {
    const res = await handbookWorksetsAPI.deletePrice(
      contractor_work_set_tariff_id,
      params
    );
    return { data: res?.data, history: res?.price_history };
  } catch (e) {}
};
export const addHiddenWorkSet = async (body, params) => {
  try {
    const res = await handbookWorksetsAPI.addHiddenWorkSet(body, params);
    return res?.data;
  } catch (e) {}
};
export const deleteHiddenWorkSet = async (
  contractor_work_set_link_id,
  params
) => {
  try {
    const res = await handbookWorksetsAPI.deleteHiddenWorkSet(
      contractor_work_set_link_id,
      params
    );
    return res?.data;
  } catch (e) {}
};
export const addContractorWorkSet = async (body, params) => {
  try {
    const res = await handbookWorksetsAPI.addContractorWorkSet(body, params);
    return res?.data;
  } catch (e) {}
};
export const updateContractorWorkSet = async (
  contractor_work_set_id,
  body,
  params
) => {
  try {
    const res = await handbookWorksetsAPI.updateContractorWorkSet(
      contractor_work_set_id,
      body,
      params
    );
    return res?.data;
  } catch (e) {}
};
export const removeContractorWorkSet = async (
  contractor_work_set_id,
  params
) => {
  try {
    const res = await handbookWorksetsAPI.removeContractorWorkSet(
      contractor_work_set_id,
      params
    );
    return res?.data;
  } catch (e) {}
};

export const getWorkSetPriceHistory = async (contractor_work_set_id) => {
  try {
    const res = await handbookWorksetsAPI.priceHistory({
      contractor_work_set_id,
    });
    return res?.data;
  } catch (e) {}
};

export const getFiltersMetaData = async () => {
  return await Promise.all([getMainContractors(), getWorkSetList()]).catch(
    () => {
      return null;
    }
  );
};

export const workSetGroupings = [
  { id: "WORK_SET_CHECK_GROUP", label: "По группам работ" },
  { id: "SPECIALITY", label: "По специализации" },
];

export const remontStatuses = [
  { id: "ALL", label: "Все" },
  { id: "ACTIVE", label: "Используются в активных ремонтах" },
  { id: "INACTIVE", label: " Не используются в активных ремонтах" },
];

export const workSetTypes = [
  { id: "ALL", label: "Все" },
  { id: "MAIN", label: "Только основные конструктивы" },
  { id: "HIDDEN", label: "Только вложенные конструктивы" },
];

export const getHandbookWorksetsColumns = (
  grouping = "WORK_SET_CHECK_GROUP",
  isContractorSelected = false
) => {
  const workSets = [
    {
      name: "work_set_id",
      field: "work_set_id",
      label: "ID",
      align: "center",
    },
    {
      name: "work_set_name",
      field: "work_set_name",
      label: "Конструктив",
      align: "left",
    },
    {
      name:
        grouping === "WORK_SET_CHECK_GROUP"
          ? "speciality_name"
          : "work_set_check_group_name",
      field:
        grouping === "WORK_SET_CHECK_GROUP"
          ? "speciality_name"
          : "work_set_check_group_name",
      label:
        grouping === "WORK_SET_CHECK_GROUP" ? "Специализация" : "Группа работ",
      align: "left",
    },
    {
      name: "essentials",
      label: "Особенности",
      align: "center",
    },
    {
      name: "nested_work_sets",
      field: "nested_work_sets",
      label: "Вложенные конструктивы",
      align: "center",
      show: !!isContractorSelected,
    },
    {
      name: "work_set_price",
      label: "Цена",
      align: "right",
      show: !!isContractorSelected,
    },
    {
      name: "actions",
      field: "actions",
      label: "Действие",
      align: "center",
      show: !isContractorSelected,
    },
  ];
  return workSets.filter((item) => item.show !== false);
};

export const workSetPriceHistoryColumns = [
  {
    name: "id",
    label: "ID",
    align: "center",
  },
  {
    name: "work_set_price",
    label: "Цена",
    align: "right",
  },
  {
    name: "essentials",
    label: "Период действия",
    align: "center",
  },
  {
    name: "actions",
    label: "",
    align: "center",
  },
];

export const workSetHiddenColumns = [
  {
    name: "id",
    label: "ID",
    align: "center",
  },
  {
    name: "work_set_name",
    field: "work_set_name",
    label: "Конструктив",
    align: "left",
  },
  {
    name: "work_set_price",
    field: "work_set_price",
    label: "Цена",
    align: "right",
  },
];
