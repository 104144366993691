<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomSelect :options="roomsOptions" label="Комната" :required="true" optionValueKey="room_id"
          optionLabelKey="room_name" :value="this.formData.room_id || ''" @change="(value) => {
            onChange('room_id', value);
            if (value) fetchTKWorkSets(this.formData.client_request_id, value);
          }" />
      </div>

      <div class="form__item">
        <CustomSelect :options="workSetsOptions" label="Конструктив" :required="true" optionValueKey="work_set_id"
          optionLabelKey="work_set_name" :value="this.formData.work_set_id || ''"
          @change="(value) => onChange('work_set_id', value)" />
      </div>
      <!-- Пустышка див чтобы кнопки были справа: -->
      <div></div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import { getTKRooms, getTKWorkSets } from '@/views/ClientRequests/remontDS/dsCreationTemplates/changeTextConstructor/services'

export default {
  name: "text-constructor-works",
  components: { CustomCheckbox, CustomModalFooter, CustomInput, CustomSelect },
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        client_request_id: this.data?.body?.client_request_id || '',
        ds_id: this.data?.body?.ds_id || '',
        room_id: this.data?.body?.room_id || '',
        work_set_id: this.data?.body?.work_set_id || '',
      },
      roomsOptions: [],
      workSetsOptions: [],
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value;

      if (key === 'room_id') {
        if (!value) {
          this.formData.work_set_id = '';
          this.workSetsOptions = [];
        } 
      }
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async fetchRooms(client_request_id) {
      this.roomsOptions = await getTKRooms(client_request_id);
    },
    async fetchTKWorkSets(client_request_id, room_id) {
      this.workSetsOptions = await getTKWorkSets(client_request_id, room_id);
    },
  },
  mounted() {
    this.fetchRooms(this.formData.client_request_id);
  }
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 6fr 6fr;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 12fr;
  }

  .form__item {
    flex: 1 1 45%;
  }
}
</style>
