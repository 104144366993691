
export const clientDataList = [
  { label: "Все", value: 2 },
  { label: "Да", value: 1 },
  { label: "Нет", value: 0 },
];

export const remontExpenses = [
  { label: "Все", value: 2 },
  { label: "Да", value: 1 },
  { label: "Нет", value: 0 },
];
export const remontTypes = [
  { label: "Все", value: 2 },
  { label: "ДДУ", value: 1 },
  { label: "Опциональный", value: 0 },
];
export const oneCStatuses = [
  { label: "Все", value: 3 },
  { label: "Отправлен в 1C", value: 2 },
  { label: "Готов к отправке", value: 1 },
  { label: "Еще не отправлен в 1C", value: 0 },
];
export const oneCAcceptedList = [
  { label: "Все", value: 2 },
  { label: "Да", value: 1 },
  { label: "Нет", value: 0 },
];