<template>
  <CustomTable
    title="Вложенные конструктивы"
    :rowsData="this.rowsData"
    :columns="this.workSetHiddenColumns"
    :loading="isFetching"
    :custom-body="true"
    :small="true"
    :show-search="false"
    :sticky-header="false"
    :add-btn="true"
    :onAddClick="this.handleAddClick"
  >
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center" verticalAlign="center">
          {{props.row?.work_set_id}}
        </custom-table-cell>
        <custom-table-cell align="left" verticalAlign="center">
          {{props.row.work_set_name}}
        </custom-table-cell>
        <custom-table-cell align="right" verticalAlign="center">
          <Amount :value="props.row.work_set_price" />
        </custom-table-cell>
        <custom-table-cell align="center" vertical-align="center">
          <CustomIcon
            v-if="!!props.row?.contractor_work_set_link_id"
            icon="trash"
            approveMode
            :approve="() => this.deleteWorkSet(props.row?.contractor_work_set_link_id)"
          />
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import Amount from "@/components/common/Amount";
import CustomIcon from "@/components/common/CustomIcon";
import CustomPopup from "@/components/common/CustomPopup";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import { addHiddenWorkSet, deleteHiddenWorkSet, getWorkSetPriceHistory } from "@/views/handbook/WorkSets/services";
import { workSetHiddenColumns } from './services';
import { MODAL_NAMES } from "@/constants";
import { appActions } from "@/services/store/modules/app_module/app_actions";

export default {
  name: "WorkSetsHidden",
  components: {CustomIcon, CustomTableCell, CustomTable,CustomPopup,Amount},
  props: ['contractor_work_set_id', 'filters', 'rowsData', 'onDataChange'],
  data() {
    return {
      isFetching: false,
      workSetHiddenColumns
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getWorkSetPriceHistory(this.contractor_work_set_id)
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.ADD_HIDDEN_WORKSET, {
        title: 'Добавить вложенный конструктив',
        contractor_work_set_id: this.contractor_work_set_id,
        onSubmit: this.onSubmitAdd,
      }));
    },
    async onSubmitAdd(body) {
      const res = await addHiddenWorkSet({...body, contractor_work_set_pid: this.contractor_work_set_id}, this.filters)
      if(!res) return
      this.onDataChange(res)
      this.$store.commit(appActions.closeModal());
      this.showSucceedSnackbar()
    },
    async deleteWorkSet(contractor_work_set_link_id) {
      if(!contractor_work_set_link_id) return
      const res = await deleteHiddenWorkSet(contractor_work_set_link_id, this.filters)
      if(!res) return
      this.onDataChange(res)
      this.showSucceedSnackbar()
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss"></style>
