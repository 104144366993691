<template>
  <q-input
    :disable="!!this.disable"
    :dense="true"
    :required="!!this.required"
    rows="2"
    ref="customInput"
    :min="this.min === undefined ? 0 : this.min"
    step="any"
    :label="`${this.label || ''} ${this.required ? '*' : ''}`"
    :class="['customInput', {customInput__autoMinWidth: !!this.autoMinWidth}]"
    :mask="inputMask"
    :type="this.inputType"
    :input-class="{customInput__hideControls: this.controls !== true, customInput__bold: this.bold}"
    v-model="model"
    @clear="clearValue"
    :loading="this.loading"
    :rules="this.rules"
    @keyup.enter="this.$emit('enter')"
  >
    <template v-slot:default v-if="!!this.title">
      <q-tooltip>
        {{this.title}}
      </q-tooltip>
    </template>
    <template v-slot:append v-if="this.type === 'password'">
      <q-tooltip
        v-if="!!this.afterTextTitle"
        anchor="top left"
        self="top left"
        :offset="[20, 5]"
      >
        {{this.afterTextTitle}}
      </q-tooltip>
    </template>
    <template
      v-if="value && this.type !== 'password' && this.showClearIcon !== false"
      v-slot:append
    >
      <span v-if="this.afterText !== undefined" class="afterText">
        {{ this.afterText }}
        <q-tooltip
          v-if="!!this.afterTextTitle"
          anchor="top left"
          self="top left"
          :offset="[20, 5]"
        >
          {{this.afterTextTitle}}
        </q-tooltip>
      </span>
      <q-icon
        name="cancel"
        @click.stop.prevent="clearValue"
        class="cursor-pointer customInput__icon"
      />
    </template>
    <template v-slot:append v-if="this.isSearch">
      <q-icon name="search" />
    </template>
    <template v-slot:append v-if="this.disable && this.showDisable">
      <q-icon name="block" />
    </template>
  </q-input>
</template>

<script>
import {customInputRules} from "@/components/form/CustomInput/services";

export default {
  name: "CustomInput",
  props: ["value", "type", "label","bold", "afterText","afterTextTitle","title", "autoMinWidth", "controls", "min",
    "required", "triggerValidationOnMount", "loading", "isSearch", "onPressEnter", "disable", "showDisable", "integer", "showClearIcon"],
  data() {
    return {
      isPwd: this.type === 'password' || null,
    }
  },
  methods: {
    clearValue() {
      this.$emit("change_input", "");
      this.$emit("clear_input", "");
    },
  },
  mounted() {
    if (this.triggerValidationOnMount)
      this.$refs.customInput.validate()
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        let value = newValue
        if (this.integer && !!newValue) {
          const intValue = parseInt(newValue, 10);
          const isCorrect = !isNaN(intValue) && intValue.toString() === newValue.toString()
          if (!isCorrect) value = parseInt(newValue, 10)
        }
        this.$emit("change_input", value);
      },
    },
    inputMask() {
      if (this.type === 'phone') return '+# (###) ### - ####'
    },
    inputType() {
      if (this.type === 'phone') return
      return this.isPwd ? 'password' : this.isPwd === null ? this.type || 'text' : 'text'
    },
    rules() {
      return customInputRules({required: this.required})
    },
  },
};
</script>

<style lang="scss">
.customInput {
  min-width: 100px;
  padding-bottom: 0 !important;

  &__autoMinWidth {
    min-width: unset;
  }

  &__icon {
    font-size: 19px;
  }

  &__bold {
    font-weight: 700 !important;
  }

  &__hideControls {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      appearance: none !important;
      margin: 0 !important;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield !important;
    }
  }

  & .q-field__bottom {
    padding-top: 3px !important;
  }
}

.afterText {
  height: 100%;
  font-size: 11px;
  display: flex;
  align-items: flex-end;
}
</style>
