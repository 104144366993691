import { techprojectDevelopersAPI } from "@/views/handbook/techprojectDevelopers/api";
import { handbookContractorsAPI } from "@/views/handbook/contractors/api";

export const getTechprojectDevelopers = async () => {
  try {
    const res = await techprojectDevelopersAPI.getList();
    return res?.data;
  } catch (e) {
  }
};
export const addHandbookTechprojectDeveloper = async (body = {}) => {
  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    formData.append(key, value);
  });
  try {
    const res = await techprojectDevelopersAPI.addTechprojectDeveloper(
      formData
    );
    return res?.data;
  } catch (e) {}
};

export const updateHandbookTechprojectDeveloper = async (
  techproject_developer_id,
  body = {}
) => {
  try {
    const res = await techprojectDevelopersAPI.updateTechprojectDeveloper(
      techproject_developer_id,
      body
    );
    return res?.data;
  } catch (e) {}
};
export const deleteHandbookTechprojectDeveloper = async (
  techproject_developer_id
) => {
  try {
    const res = await techprojectDevelopersAPI.deleteTechprojectDeveloper(
      techproject_developer_id
    );
    return res?.data;
  } catch (e) {}
};
export const getCity = async () => {
  try {
    const res = await handbookContractorsAPI.getCity();
    return res?.data;
  } catch (e) {}
};
export const getContragentType = async () => {
  try {
    const res = await handbookContractorsAPI.getContragentType();
    return res?.data;
  } catch (e) {}
};

export const techprojectDevelopersColumns = [
  {
    name: "techproject_developer_id",
    field: "ID",
    label: "ID",
    align: "center",
  },
  {
    name: "techproject_developer_name",
    field: "contractor_name",
    label: "Наименование",
    align: "left",
  },
  {
    name: "director_fio",
    field: "director_fio",
    label: "ФИО директора",
    align: "left",
  },
  { name: "documents", field: "documents", label: "Документы", align: "left" },
  {
    name: "legal_name",
    field: "legal_name",
    label: "Юридическое наименование",
    align: "left",
  },
  { name: "city_name", field: "city_name", label: "Город", align: "left" },
  { name: "address", field: "address", label: "Адрес", align: "left" },
  { name: "bin", field: "bin", label: "БИН", align: "left" },
  { name: "bik", field: "bik", label: "БИК", align: "left" },
  { name: "iik", field: "iik", label: "ИИК", align: "left" },
  { name: "kbe", field: "kbe", label: "КБЕ", align: "left" },
  {
    name: "bank_name",
    field: "bank_name",
    label: "Наименование банка",
    align: "left",
  },
  {
    name: "contract_text",
    field: "contract_text",
    label: "Текст для договора",
    align: "left",
  },
  { name: "guid", field: "guid", label: "ГУИД", align: "left" },
  { name: "phone", field: "phone", label: "Телефон", align: "left" },
  { name: "email", field: "email", label: "Email", align: "left" },
  {
    name: "is_send_to_1c_text",
    field: "is_send_to_1c_text",
    label: "1C",
    align: "center",
  },
  { name: "edit", field: "edit", label: "Ред.", align: "center" },
  { name: "delete", field: "delete", label: "Удалить", align: "center" },
];
