<template>
  <i
    :class="[`material-icons icon icon__${
                  this.value ? 'check' : 'close'
                }`, {icon__noPadding: this.noPadding, icon__small: this.small}]"
    >{{ this.value ? "check" : this.onlyTrue ? '' : "close" }}</i
  >
</template>

<script>
export default {
  name: "check-or-close",
  props: {
    value: Boolean,
    onlyTrue: Boolean,
    noPadding: Boolean,
    small: Boolean,
  }
}
</script>

<style scoped lang="scss">
.icon {
  font-size: 20px;
  padding: 5px;
  transition: all 0.3s;

  &__close {
    color: #9d1010;
  }

  &__small {
    font-size: 16px;
  }

  &__check {
    color: #55c558;
  }
  &__noPadding {
    padding: 0;
  }

  &__edit {
    border-radius: 50%;
    color: #cc6d33;

    &:hover {
      background: #ebebeb;
    }
  }
}
</style>
