import instance from "@/services/api";

export const handbookWorksetsAPI = {
  async getList(params) {
    return await instance()
      .get("/handbook/work_set/read/", { params })
      .then((res) => res?.data);
  },
  async getWorksetCheckGroups() {
    return await instance()
      .get("/common/work_set_check_groups/read/")
      .then((res) => res?.data);
  },
  async updateHandbookWorkset(work_set_id, body, params) {
    return await instance()
      .put(`/handbook/work_set/${work_set_id}/update/`, body, { params })
      .then((res) => res?.data);
  },
  async getSpecialities() {
    return await instance()
      .get(`/common/specialities/read/`)
      .then((res) => res?.data);
  },
  async addPrice(body, params) {
    return await instance()
      .post(`/handbook/work_set/price/add/`, body, { params })
      .then((res) => res?.data);
  },
  async priceHistory(body) {
    return await instance()
      .post(`/handbook/work_set/price_history/`, body)
      .then((res) => res?.data);
  },
  async deletePrice(contractor_work_set_tariff_id, params) {
    return await instance()
      .delete(
        `/handbook/work_set/price/${contractor_work_set_tariff_id}/delete/`,
        { params }
      )
      .then((res) => res?.data);
  },
  async addHiddenWorkSet(body, params) {
    return await instance()
      .post(`/handbook/work_set/link/add/`, body, { params })
      .then((res) => res?.data);
  },
  async deleteHiddenWorkSet(contractor_work_set_link_id, params) {
    return await instance()
      .delete(
        `/handbook/work_set/link/${contractor_work_set_link_id}/delete/`,
        { params }
      )
      .then((res) => res?.data);
  },
  async addContractorWorkSet(body, params) {
    return await instance()
      .post(`/handbook/work_set/cws/add/`, body, { params })
      .then((res) => res?.data);
  },
  async removeContractorWorkSet(contractor_work_set_id, params) {
    return await instance()
      .delete(`/handbook/work_set/cws/${contractor_work_set_id}/delete/`, {
        params,
      })
      .then((res) => res?.data);
  },
  async updateContractorWorkSet(contractor_work_set_id, body, params) {
    return await instance()
      .put(`/handbook/work_set/cws/${contractor_work_set_id}/update/`, body, {
        params,
      })
      .then((res) => res?.data);
  },
};
