<template>
  <q-form @submit="this.onSubmit" class="form">
    <div class="form__item">
      <CustomSelect
        :options="this.workSets"
        label="Конструктив"
        optionValueKey="work_set_id"
        optionLabelKey="work_set_name"
        :value="this.formData.work_set_id || ''"
        @change="(value) => onChange('work_set_id', value)"
      />
    </div>
    <div class="form__item">
      <div>
        <CustomCheckbox
          label="Без оплаты"
          :value="this.formData.without_pay"
          @change="(value) => onChange('without_pay', value)"
        />
      </div>
    </div>
    <CustomModalFooter :btn-loading="this.loading" />
  </q-form>
</template>
<script>

import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomSelect from "@/components/form/CustomSelect";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import { getWorkSetList } from "@/services/api/api";

export default {
  name: "add-handbook-contractor-workset",
  components: {CustomSelect, CustomModalFooter, CustomCheckbox},
  props: ['data'],
  data() {
    return {
      loading: false,
      workSets: [],
      formData: {
        without_pay: this.data?.body?.without_pay || false,
        work_set_id: this.data?.body?.work_set_id || '',
      }
    }
  },
  methods: {
    async getData(){
      const res = await getWorkSetList()
      this.workSets = res || []
    },
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 15px;

  @media (max-width: 460px) {
   width: 100%;
  }
}
</style>
