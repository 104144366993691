<template>
  <q-form @submit="this.onSubmit" class="form">
    <div class="form__item">
      <CustomSelect
        :options="this.worksetGroups"
        label="Группа работ"
        optionValueKey="work_set_check_group_id"
        optionLabelKey="work_set_check_group_name"
        :value="this.formData.work_set_check_group_id || ''"
        @change="(value) => onChange('work_set_check_group_id', value)"
      />
    </div>
    <div class="form__item">
      <CustomSelect
        :options="this.worksetGroups"
        label="Зависимая группа работ"
        optionValueKey="work_set_check_group_id"
        optionLabelKey="work_set_check_group_name"
        :value="this.formData.service_call_work_set_group_id || ''"
        @change="(value) => onChange('service_call_work_set_group_id', value)"
      />
    </div>
    <div class="form__item">
      <CustomSelect
        :options="this.specialities"
        label="Специализация"
        optionValueKey="speciality_id"
        optionLabelKey="speciality_name"
        :value="this.formData.speciality_id || ''"
        @change="(value) => onChange('speciality_id', value)"
      />
    </div>
    <div class="form__item">
      <div>
        <CustomCheckbox
          label="Вызов мастера"
          :value="this.formData.is_call"
          @change="(value) => onChange('is_call', value)"
        />
      </div>
      <div>
        <CustomCheckbox
          label="Поставщик SR"
          :value="this.formData.is_service"
          @change="(value) => onChange('is_service', value)"
        />
      </div>
      <div>
        <CustomCheckbox
          label="Оплата онлайн"
          :value="this.formData.is_pay_online"
          @change="(value) => onChange('is_pay_online', value)"
        />
      </div>
    </div>
    <CustomModalFooter :btn-loading="this.loading" />
  </q-form>
</template>
<script>

import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";

export default {
  name: "edit-handbook-workset",
  components: {CustomSelect, CustomModalFooter, CustomCheckbox},
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        is_call: this.data?.body?.is_call || false,
        is_service: this.data?.body?.is_service || false,
        is_pay_online: this.data?.body?.is_pay_online || false,
        work_set_check_group_id: this.data?.body?.work_set_check_group_id || '',
        service_call_work_set_group_id: this.data?.body?.service_call_work_set_group_id || '',
        speciality_id: this.data?.body?.speciality_id || '',
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
  computed: {
    worksetGroups() {
      return this.$store.state.app.worksetGroups;
    },
    specialities() {
      return this.$store.state.app.specialities;
    },
  },
  mounted() {
    this.$store.dispatch(appActionTypes.getWorksetCheckGroups)
    this.$store.dispatch(appActionTypes.getSpecialities)
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 15px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
