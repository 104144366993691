<template>
  <div
    :class="[`${this.className || ''} collapseBlock`, { collapseBlock__collapsed: this.collapsed,collapseBlock__hide: this.hideExpand && this.collapsed, collapseBlock__tablet: this.tabletMode }]">
    <slot></slot>
    <div
      :class="['collapseBlock__expand', { collapseBlock__expand_active: this.collapsed, collapseBlock__expand_hide: this.hideExpand && this.collapsed, collapseBlock__expand_tablet: !!this.tabletMode }]"
      @click="this.toggleCollapse">
      <q-icon size="2em" color="primary" name="expand_more"
        :class="['collapseBlock__expand_collapse', { collapseBlock__expand_collapsed: this.collapsed }]" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CollapseBlock",
  props: {
    'onChange': Function,
    'className': String,
    'tabletMode': Boolean,
    'defaultValue': Boolean,
    'controlMode': Boolean,
    'collapsedProps': Boolean,
    'hideExpand': Boolean,
  },
  data() {
    return {
      collapsedLocal: !!this.defaultValue
    }
  },
  methods: {
    toggleCollapse() {
      if (this.controlMode)
        if (this.onChange)
          return this.onChange(!this.collapsedProps)
      this.collapsedLocal = !this.collapsedLocal
      if (this.onChange)
        this.onChange(this.collapsedLocal)
    }
  },
  computed: {
    collapsed() {
      if (this.controlMode)
        return this.collapsedProps
      return this.collapsedLocal
    },
  },
}
</script>

<style scoped lang="scss">
.collapseBlock {
  position: relative;
  transition: all .3s;
  padding-bottom: 33px !important;

  &__hide {
    opacity: 0;
  }

  &__collapsed {
    max-height: 31px !important;
    padding: 5px 0 0 0 !important;
    overflow: hidden !important;
    margin-top: 10px !important;
    border-radius: 0 0 15px 15px !important;
  }

  &__tablet {
    padding-bottom: 0 !important;
    max-height: unset !important;

    @media (max-width: 991px) {
      &.collapseBlock__collapsed {
        max-height: 31px !important;
      }

      padding-bottom: 28px !important;
    }
  }

  &__expand {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 15px 15px;
    cursor: pointer;
    background: #f5f5f5;
    transition: all .3s;
    z-index: 1001;

    &_tablet {
      display: none;

      @media (max-width: 991px) {
        display: flex;
      }
    }

    &_active {
      bottom: -2px;
      height: 31px;
    }

    &_hide {
      display: none
    }

    &_collapse {
      transition: all .3s;
      transform: rotate(180deg);
    }

    &_collapsed {
      transform: rotate(0);
    }
  }
}
</style>